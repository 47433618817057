<template>
	<div
		class="flex-column full-height"
	>
		<div
			class="bg-title"
		>
			<button
				:title="program.name"
				@click="toBack()"
			><v-icon large class="">mdi-chevron-left</v-icon><span class=" font-weight-bold size-em-15 vertical-middle">{{ program.name }}</span></button>
		</div>

		<div
			class="pa-20 flex-column full-height overflow-y-auto"
		>
			<div
				class="mt-10"
			>
				<h6>대리점 코드</h6>
				<input
					v-model="item.admin_code"
					type="text"
					class="mt-10 pa-10 input-underline"
					placeholder="대리점 코드를 입력하세요"
					:disabled="isGetAdminCode"
				/>
			</div>

			<div
				class=" mt-10"
			>
				<h6>이름</h6>
				<div
					class="pa-10 input-underline"
				>{{ item.member_name }} {{ !is_nice ? '본인인증이 필요합니다.' : ''}}</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>연락처</h6>
				<div
					class="pa-10 input-underline"
				>{{ item.member_tell }} {{ !is_nice ? '본인인증이 필요합니다.' : ''}}</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>생년월일</h6>
				<div
					class="pa-10 input-underline"
				>{{ item.member_birth }} {{ !is_nice ? '본인인증이 필요합니다.' : ''}}</div>
			</div>

			<div
				v-if="false"
				class="mt-10"
			>
				<h6>이메일</h6>
				<input
					v-model="item.member_email"
					type="text" placeholder="이메일을 입력하세요"
					class="input-underline"
					maxlength="50"
				/>
				<div
					v-if="this.txtValidCheckEmail != ''"

					class="mt-10 text-right color-red"
				>{{ this.txtValidCheckEmail }}</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>이용약관 및 쇼핑정보 수신동의</h6>
				<div
					class="mt-10 input-box"
				>이용약관</div>

				<div
					class="mt-10 mb-30"
					@click="item.is_agree = !item.is_agree"
				>
					<v-icon
						v-if="item.is_agree"
						class="color-green"
					>mdi mdi-checkbox-marked</v-icon>
					<v-icon
						v-else
					>mdi mdi-checkbox-blank-outline</v-icon>

					이용약관 및 SMS 수신 동의
				</div>
			</div>
		</div>

		<div
			class="bottom"
		>
			<button
				v-if="!is_nice"
				class="btn btn-identify"
				@click="onNice"
			>
				본인 인증
			</button>
			<button
				v-else-if="is_bank"
				:disabled="!isNext"
				class="btn btn-identify"
				@click="next"
			>다음</button>
			<button
				v-else
				:disabled="!isNext"
				class="btn btn-identify"
				@click="onBank"
			>계좌인증</button>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"
		></DaumPost>

		<Bank
			v-if="is_on_bank"
			:Axios="Axios"
			:user="item"

			@cancel="is_on_bank = false"
			@click="setBank"
		></Bank>

	</div>
</template>


<script>

import DaumPost from '@/components/Daum/DaumPost'
import Bank from "./Bank";
import {Base64} from "js-base64";

export default{
	name: 'Guest'
	,props: ['Axios', 'codes', 'rules']
	, components: {Bank, DaumPost }
	,data: function(){
		return {
			program: {
				name: '비회원 로그인'
				, code: 'join'
				, top: false
				, title: true
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
			}
			,search: {
				COMPFRNAME: ''
			}
			,daumPostUp: false
			,txtValidCheckPass: ''
			,txtValidCheckEmail: ''
			,txtValidCheckCompany: ''
			,item: {
				member_id: ''
				, member_email: ''
				, admin_code: this.$route.params.code
				, is_agree: false
				, sms_confirm: false
				, member_tell: ''
				, sms_code: ''
				, is_use_sms_auth: true
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, member_birth: ''
				, nice_uid: ''
			}
			, is_check_id: false
			, is_nice: false
			, is_bank: false
			, is_on_bank: false
		}
	}
	,computed: {
		isNext: function(){
			const self = this
			const required = ['member_id', 'member_password', 'member_password_confirm', 'member_name', 'member_post', 'member_addr1', 'member_addr2', 'member_tell', 'member_email', 'is_agree']
			let isNext = false
			for(let i = 0; i < required.length; i++){
				if(!self.item[required[i]]){
					isNext = true
					break
				}
			}

			// 비밀번호 유효성 검사
			let isPass = this.rules.password(self.item, 'member_password')
			if(isPass === true){
				self.txtValidCheckPass = ''
			}else{
				self.txtValidCheckPass = isPass
				isNext = true
			}

			// 비밀번호 체크
			if(self.item['member_password_confirm'] != self.item['member_password']){
				isNext = true
			}

			// 이메일 유효성 검사
			if(this.rules.email(self.item, 'member_email')){
				self.txtValidCheckEmail = ''
			}else{
				if(self.item.member_email.length == 0){
					self.txtValidCheckEmail = ''
				}else{
					self.txtValidCheckEmail = '잘못된 형식의 이메일 주소입니다.'
				}

				isNext = true
			}

			return isNext
		}
		,isGetAdminCode: function(){
			if(this.$route.params.code){
				return true
			}else{
				return false
			}
		}
		,isCheckId: function(){
			if(this.is_check_id){
				return true
			}else{
				return false
			}
		}
		, is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = false
			}
			return t
		}
		, is_member_tell: function(){
			let t = false
			if(this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = true
			}
			return t
		}
	}
	,methods: {
		daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		,next: async function(){

			try{

				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postGuest'
					,data: this.item
				})
				if(result.success){
					sessionStorage.setItem(process.env.VUE_APP_NAME + 'G', Base64.encode(this.item.nice_uid))
					this.toMain()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,toJoinResult: function(){
			this.$router.push({ name: 'JoinResult'})
		}
		,toBack: function(){
			this.$router.back()
		}
		, resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postSmsAuth'
					,data: {
						member_tell: this.item.member_tell
						, admin_code: this.item.admin_code
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postSmsConfirm'
					,data: {
						member_tell: this.item.member_tell
						, sms_code: this.item.sms_code
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, niceResult: function(e){
			console.log('nice result', e)
			let domains = process.env.VUE_APP_DOMAIN
			let t = false
			if(e.origin.indexOf(domains) > -1){
				t = true
			}
			if(t){
				try {
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'nice_result'){
							if(data.result){
								this.item.member_name = data.data.name
								this.item.member_tell = data.data.phone
								this.item.member_birth = data.data.birthdate
								this.item.nice_uid = data.data.nice_uid
								this.is_nice = true
							}else{
								throw data.message
							}
						}
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('offNice')
					this.$bus.$emit('on', false)
				}
			}
		}
		, destroyed() {
			window.removeEventListener('message', this.niceResult)
		}
		, onBank: function(){
			this.is_on_bank = true
		}
		, onNice: function(){
			this.$bus.$emit('onNice', 'request?type=guest&admin_code=' + this.item.admin_code)
			window.addEventListener('message', this.niceResult)
		}
		, setBank: function(item){
			this.$set(this.item, 'bank_code', item.bank_code)
			this.$set(this.item, 'bank_account', item.bank_account)
			this.$set(this.item, 'bank_holder', item.bank_holder)

			this.next()
		}
		,toMain: function(){
			document.location.href = '/Index'
		}
	}
	,created: function(){
		this.$emit('setProgram', this.program)
	}
}
</script>

<style>

</style>